import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { CreateEmailComponent } from '../create-email/create-email.component';
import { SubscribePopUpComponent } from '../subscribe-pop-up/subscribe-pop-up.component';
@Component({
  selector: 'app-rightimage',
  templateUrl: './rightimage.component.html',
  styleUrl: '../../style.css',
})
export class RightimageComponent {
  @Input() rightImageData: any;
  
  dialogRef: MatDialogRef<SubscribePopUpComponent> | null | undefined;
  constructor(public dialog: MatDialog, private router: Router) {
    this.dialogRef = null;
  }

  openModal(page: string) {
    const dialogRef = this.dialog.open(SubscribePopUpComponent, {
      width: '1100px',
      maxHeight: '760px',
      // height: 'max-content',
      // height: '700px',

      // panelClass: 'email-container',
      disableClose: true, // Optional: prevents closing the dialog by clicking outside or pressing ESC
      data: {
        btnTxt: 'Submit',
        heading: 'Unlock Your Supply Chain\'s Potential - Get Our Brochure Now!',
        imgUrl: '../../../../assets/imgs/DownloadPopUp.png',
        thanksTxt: 'Your request for our company profile has been received. Check your email shortly for more details.',
        thanksHeading: `<span class='text-success'>Thank You 🎉</span> `
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.status) {
      }
    });
  }


  redirect(btnLink: string) {
    if (btnLink.includes('http') || btnLink.includes('www')) {
      window.open(btnLink, '_blank');
      return;
    }
    if (this.rightImageData.btnTxt === 'Get Started' && this.rightImageData.heading !== `Flexible  logistics solutions,
      Technology combined with expertise, Deliver on your promises to your customers`) {
      const queryParams = { path: 'conexa' };
      this.router.navigate([btnLink], { queryParams })
    }
    else {

      this.router.navigate([btnLink])
    }
  }
}
