<section class="container-fluid" [ngClass]="rightImageData?.class ?? ''">
  <p
    class="fw-medium d-flex align-items-center justify-content-start py-0 my-0 mb-5"
    style="cursor: pointer; " *ngIf="rightImageData?.backBtnTxt ?? ''"
    (click)="redirect( rightImageData?.backBtnLink ?? '')">
    <button
      class="hover-bg-blue rounded-5 border-0 bg-white me-3 d-flex align-items-center justify-content-center pe-2 py-1">
      <i class="bi bi-chevron-left text-primary"></i>
    </button>
    Back to {{rightImageData.backBtnTxt}}
  </p>
  <div class="row">

    <!-- <div class="row align-items-center"> -->
    <div class="col-lg-5 col-md-12 order-lg-1 order-3"
      [ngClass]="rightImageData.txtSecClass">
      <div class="text-content">

        <h1 [innerHTML]="rightImageData.heading"
          [ngClass]="rightImageData.headingClass"></h1>
        <p [innerHTML]="rightImageData.description"
          [ngClass]="rightImageData.pTagClass"></p>
        <button class="btn btn-primary"
          *ngIf="rightImageData.btnTxt && rightImageData.btnTxt.includes('Download PDF')"
          [innerHtml]="rightImageData.btnTxt" (click)="openModal('download')">
        </button>
        <button class="btn"
          [ngClass]="rightImageData.btnClass ? rightImageData.btnClass : 'btn-primary'"
          *ngIf="
            rightImageData.btnTxt && !rightImageData.btnTxt.includes('Download PDF') 
          " (click)="redirect( rightImageData.btnLink )">
          {{ rightImageData.btnTxt }}
        </button>
      </div>
    </div>

    <div class="col-lg-1 col-md-2 order-lg-2 order-2"></div>

    <div class="col-lg-6 col-md-12 order-lg-3 order-1">
      <figure class="image-container "
        [ngClass]="rightImageData.figureTagClass">
        <!-- If the file is an image, render the img tag -->
        <img
          *ngIf="rightImageData.img.endsWith('.jpg') || rightImageData.img.endsWith('.png') || rightImageData.img.endsWith('.jpeg') || rightImageData.img.endsWith('.svg') || rightImageData.img.endsWith('.gif')"
          [src]="rightImageData.img" loading="eager" alt="Logistics solutions"
          class="img-fluid" [ngClass]="rightImageData.imgClass" />

        <!-- If the file is a video, render the video tag -->
        <video *ngIf="rightImageData.img.endsWith('.mp4')"
          [src]="rightImageData.img" class="img-fluid"
          autoplay
          loop
          playsinline
          muted>
          Your browser does not support the video tag.
        </video>
      </figure>
    </div>
  </div>
</section>